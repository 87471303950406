.loading {
  width: 250px;
  height: 150px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--white);
  border-radius: 10px;
  gap: 15px;
}

.loading > svg {
  width: 50px !important;
  height: 50px !important;
  animation: 1s spin infinite linear;
}

.infoButton {
  font-style: normal;
  width: 30px;
  height: 30px;
  border-radius: 100%;
  background-color: var(--white);
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 2;

  cursor: default;
}

.info {
  position: absolute;
  top: 40px;
  background-color: var(--white);
  border-radius: 5px;
  padding-inline: 10px;
  padding-block: 5px;
  box-sizing: border-box;
  /* transform: translate(-50%, 0%); */
  right: 0;
  box-shadow: -10px 5px 30px rgb(61 95 143 / 25%);
  width: max-content;
  max-width: 230px;
}

.info:before {
  content: "";
  width: 10px;
  height: 10px;
  background-color: var(--white);
  border-radius: 1px;
  position: absolute;
  transform: translate(-50%, -50%) rotate(45deg);
  right: 5px;
  top: 0;
}

.homeContentBox .infoTitle {
  font-size: 24px;
  color: var(--white);
  text-align: center;
}

.homeContentColumn {
  z-index: 0;
}

@media screen and (max-width: 1320px) {
  .homeContentBox .infoTitle {
    font-size: 20px;
    padding-top: 20px;
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
