.secondaryNav > nav {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  width: fit-content;
  gap: 10px;
  justify-content: left;
}

.secondaryNav {
  display: flex;
  justify-content: center;
  align-items: center;

  position: absolute;
  top: 75vh;
  left: 50%;
  transform: translate(-50%, 100%);

  width: 90vw;

  transition-duration: 0.3s;

  z-index: 1;
}

.navigationButton {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  padding: 8px 15px;

  border-radius: 5px;

  cursor: pointer;

  font-weight: 550;

  text-decoration: none;
}

.navigationButton > svg {
  height: 27px;
  width: 27px;
}

.navigationButton.blue {
  background-color: var(--blue);
  color: var(--white);
  fill: var(--white);
}

.navigationButton.orange {
  background-color: var(--brand);
  color: var(--white);
  fill: var(--white);
}

.navigationButton.green {
  background-color: var(--green);
  color: var(--white);
  fill: var(--white);
}

.navigationButton.magenta {
  background-color: var(--magenta);
  color: var(--white);
}

.navigationButton.magenta > svg {
  stroke: var(--white);
}

.navigationButton.purple {
  background-color: var(--purple);
  color: var(--white);
  fill: var(--white);
}

.navigationButton.pink {
  background-color: var(--pink);
  color: var(--white);
  fill: var(--white);
}

.navigationButton.white {
  background-color: var(--white);
  color: var(--blue);
  box-shadow: 0px 4px 15px rgba(0, 128, 255, 0.15),
    0px 8px 25px rgba(0, 128, 255, 0.09);
}

@media screen and (max-width: 1000px) {
  .secondaryNav {
    display: none;
  }
}

@media screen and (max-width: 1290px) {
  .secondaryNav {
    top: 60vh;
  }
  .navigationButton {
    gap: 6px;
    padding: 7px 10px;
    font-weight: 500;
    font-size: 14px;
  }
  .secondaryNav > nav {
    gap: 7px;
    width: fit-content;
  }
  .navigationButton > svg {
    height: 22px;
    width: 22px;
  }
  .secondaryNav {
    top: 77.5vh;
  }
}
