/* HAKKIMIZDA */
.subPage_okulumuz-aboutUs {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-start;
  gap: 70px;
  width: 800px;
  background: linear-gradient(
      126deg,
      rgba(41, 95, 229, 0.5) 10.56%,
      rgba(0, 0, 0, 0) 50.83%
    ),
    linear-gradient(0deg, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)),
    url(../../../assets/compressed/DJI_0061.webp);
  background-size: cover;
  color: var(--white);
  border-radius: 15px;
  padding: 30px;
  box-sizing: border-box;
}

.subPage_okulumuz-aboutUs > p {
  font-size: 13px;
}

.subPage_okulumuz-aboutUs_imageAndQuote {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
}

.subPage_okulumuz-aboutUs_image {
  border-radius: 100%;
  width: 180px;
  height: 180px;
  border: 4px solid var(--white);
  box-sizing: border-box;
}

.subPage_okulumuz-aboutUs_quote {
  font-weight: 600;
  width: 120%;
}

/* BINAMIZ */
.subPage_okulumuz-infoCard_wrap {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 30px;
}

.subPage_okulumuz-infoCard {
  display: flex;
  flex-direction: row;
  gap: 40px;
  justify-content: center;
  align-items: center;
  width: 800px;
  padding: 30px;
  border-radius: 15px;
  box-sizing: border-box;
}

.subPage_okulumuz-infoCard > img {
  width: 350px;
  border-radius: 10px;
}

.subPage_okulumuz-infoCard_textWrap {
  color: var(--white);
  display: flex;
  flex-direction: column;
  gap: 10px;
  justify-content: center;
  align-items: flex-start;
}

.subPage_okulumuz-infoCard:nth-child(even) {
  flex-direction: row-reverse;
}

.subPage_okulumuz-infoCard:nth-child(1) {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(../../../assets/compressed/DJI_0061.webp);
}

.subPage_okulumuz-infoCard:nth-child(2) {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(../../../assets/compressed/TKM_4336.webp);
}

.subPage_okulumuz-infoCard:nth-child(3) {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(../../../assets/compressed/TKM_7367.webp);
}

.subPage_okulumuz-infoCard:nth-child(4) {
  background: linear-gradient(0deg, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
    url(../../../assets/compressed/TKM_3909.webp);
}

.subPage_okulumuz-infoCard_textWrap > h3 {
  font-size: 16px;
}

.subPage_okulumuz-infoCard_textWrap > p {
  font-size: 14px;
}

/* TABLE */
.tableWithNoteWrap {
  width: 970px;
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.tableWithNoteWrap > p {
  opacity: 0.7;
}

.table {
  border-radius: 10px;
  border-spacing: 0;
  overflow: hidden;
  width: 100%;
}

.table.withBorders th,
.table.withBorders td {
  border-inline: 1px solid var(--white);
}

.table th,
.table td {
  color: var(--white);
  padding: 10px;
}

.table tr {
  background-color: #00b2ff;
}

.table tr:nth-child(even) {
  background-color: #088fc9;
}

/* VELI GORUSLERI */
.veliGorusleri {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

@media screen and (max-width: 1025px) {
  .tableWithNoteWrap {
    width: 90vw;
  }
}

/* ANNOUNCEMENTS */
.subPage_okulumuz-announcements_wrap {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 15px;

  max-width: 1150px;
}

.subPage_okulumuz-announcement_card {
  width: 275px;
  height: 450px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  border-radius: 10px;
  box-sizing: border-box;
  color: var(--white);
}

.subPage_okulumuz-announcement_card:nth-child(6n + 1) {
  background-color: var(--blue);
}

.subPage_okulumuz-announcement_card:nth-child(6n + 2) {
  background-color: var(--darkOrange);
}

.subPage_okulumuz-announcement_card:nth-child(6n + 3) {
  background-color: var(--purple);
}

.subPage_okulumuz-announcement_card:nth-child(6n + 4) {
  background-color: var(--blurple);
}

.subPage_okulumuz-announcement_card:nth-child(6n + 5) {
  background-color: var(--darkMagenta);
}

.subPage_okulumuz-announcement_card:nth-child(6n + 6) {
  background-color: var(--brand);
}

.subPage_okulumuz-announcement_card_details {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 5px;
}

.subPage_okulumuz-announcement_card_image {
  width: 100%;
  border-radius: 7px;
  aspect-ratio: 16 / 9;
  object-fit: cover;
  object-position: center;
}

.subPage_okulumuz-announcement_card_title {
  font-size: 17px;
  font-weight: 500;
  margin-top: 15px;
  overflow: hidden;
  max-height: 100%;
  width: stretch;
  display: -moz-box;
  display: -webkit-box;
  line-clamp: 3;
  -webkit-line-clamp: 3;
  -moz-box-orient: vertical;
  -webkit-box-orient: vertical;
}

.subPage_okulumuz-announcement_card_description {
  font-size: 15px;
  overflow: hidden;
  width: stretch;
  max-height: 100%;
  display: -moz-box;
  display: -webkit-box;
  line-clamp: 6;
  -webkit-line-clamp: 6;
  -moz-box-orient: vertical;
  -webkit-box-orient: vertical;
  opacity: 0.9;
}

.subPage_okulumuz-announcement_card_button {
  text-decoration: none;
  text-align: center;
  padding: 10px 5px;
  color: var(--black);
  background: var(--white);
  border-radius: 5px;
  width: stretch;
  border: none;
  cursor: pointer;
}

.announcementModalDescription {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.announcementModalDescription a {
  color: var(--blue);
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
}

.pageControls > span {
  width: 50px;
  height: 50px;
  background: var(--white);
  border: 1px solid var(--blue);
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.pageControls {
  display: flex;
  flex-direction: row;
  gap: 5px;
  font-size: 20px;
}

.pageControls > button {
  width: 40px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: none;
  background: var(--blue);
  border-radius: 5px;
}

.pageControls > button > svg {
  stroke: var(--white);
  width: 15px;
  height: 15px;
}

.modal:is(.announcement) {
  width: 600px;
  gap: 20px;
  box-sizing: border-box;
}

@media screen and (max-width: 630px) {
  .modal:is(.announcement) {
    width: calc(100vw - 30px);
    padding: 15px;
  }
}

.modalTitle:is(.announcement) {
  font-size: 18px;
}

.announcementModalDetails {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-height: 75vh;
  overflow-y: scroll;
}

.announcementModalDetails::-webkit-scrollbar {
  width: 15px;
  border-radius: 20px;
  cursor: pointer;
}

.announcementModalDetails::-webkit-scrollbar-track {
  border-radius: 0;
  cursor: pointer;
}

.announcementModalDetails::-webkit-scrollbar-thumb {
  width: 15px;
  background: rgba(0, 0, 0, 0.3);
  border: 5px solid var(--white);
  border-radius: 15px;
  cursor: pointer;
}

.announcementModalDetails::-webkit-scrollbar-thumb:hover {
  background: rgba(0, 0, 0, 0.5);
}

.announcementModalDetails > img {
  width: 100%;
  border-radius: 10px;
}

/* EBROSUR */
.subPages_okulumuz-ebrosur_whitespace {
  position: relative;
  display: flex;
  flex-direction: row;
  gap: 0;
}

.subPages_okulumuz-ebrosur {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-user-drag: none;
  width: 100% !important;
  height: 60vh !important;
}

.subPages_okulumuz-ebrosur_page > canvas {
  width: 100% !important;
  height: 60vh !important;
}

.subPages_okulumuz-ebrosur_page > div {
  display: none;
}

.subPages_okulumuz-ebrosur_chevron {
  stroke: var(--black);
  width: 50px;
  background: rgba(0, 0, 0, 0.2);
  border: none;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  transition-duration: 0.3s;
}

.subPages_okulumuz-ebrosur_chevron:hover {
  background: rgba(0, 0, 0, 1);
  stroke: var(--white);
}

.subPages_okulumuz-ebrosur_chevron.left {
  border-radius: 10px 0 0 10px;
}

.subPages_okulumuz-ebrosur_chevron.right {
  border-radius: 0 10px 10px 0;
}

.subPages_okulumuz-ebrosur_pageDisplay {
  background: var(--white);
  border-radius: 3px;
  padding: 15px 20px;
  border: 2px solid var(--gray);
  box-sizing: border-box;
  transform: translateX(-50%);
  left: 50%;
  position: absolute;
  bottom: 15px;
}

/* EGITIM ANLAYISIMIZ */
.subPages_okulumuz-egitimAnlayisimiz_wrap {
  display: flex;
  flex-direction: column;
  gap: 30px;
}

.subPages_okulumuz-egitimAnlayisimiz_card {
  width: 800px;
  display: flex;
  flex-direction: row;
  gap: 30px;
  justify-content: center;
  align-items: flex-start;
  color: var(--white);
  box-sizing: border-box;
  padding: 20px;
  border-radius: 10px;
  font-size: 14px;
}

.subPages_okulumuz-egitimAnlayisimiz_card:nth-child(1) {
  background-color: var(--brand);
}

.subPages_okulumuz-egitimAnlayisimiz_card:nth-child(2) {
  background-color: var(--purple);
}

.subPages_okulumuz-egitimAnlayisimiz_card:nth-child(3) {
  background-color: var(--magenta);
}

.subPages_okulumuz-egitimAnlayisimiz_card:nth-child(4) {
  background-color: var(--blue);
}

.subPages_okulumuz-egitimAnlayisimiz_card:nth-child(5) {
  background-color: var(--darkOrange);
}

.subPages_okulumuz-egitimAnlayisimiz_card:nth-child(6) {
  background-color: #047804;
}

.subPages_okulumuz-egitimAnlayisimiz_firstColumn {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 240px;
}

.subPages_okulumuz-egitimAnlayisimiz_firstColumn h1 {
  font-size: 18px;
}

.subPages_okulumuz-egitimAnlayisimiz_firstColumn > div {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.subPages_okulumuz-egitimAnlayisimiz_firstColumn > img {
  border-radius: 10px;
}

.subPages_okulumuz-egitimAnlayisimiz_secondColumn,
.subPages_okulumuz-egitimAnlayisimiz_thirdColumn {
  width: 100%;
}

@media screen and (max-width: 895px) {
  .subPage_okulumuz-aboutUs {
    width: 85vw;
    gap: 40px;
    flex-direction: column;
  }

  .subPage_okulumuz-aboutUs_quote {
    width: 60%;
  }

  .subPages_okulumuz-ebrosur_page > canvas {
    width: 100% !important;
    height: 100% !important;
  }

  .subPages_okulumuz-ebrosur {
    width: 100% !important;
    height: 100% !important;
  }

  .subPages_okulumuz-ebrosur_chevron {
    width: 42px;
  }

  .subPages_okulumuz-ebrosur_pageDisplay {
    padding: 10px 15px;
  }
}

@media screen and (max-width: 860px) {
  .subPage_okulumuz-infoCard {
    width: 90vw;
    flex-direction: column !important;
    padding: 20px;
  }

  .subPage_okulumuz-infoCard > img {
    width: 100%;
  }

  .subPages_okulumuz-egitimAnlayisimiz_card {
    width: 90vw;
    flex-direction: column;
  }

  .subPages_okulumuz-egitimAnlayisimiz_firstColumn {
    flex-direction: column-reverse;
  }
}

@media screen and (max-width: 650px) {
  .subPage_okulumuz-aboutUs {
    width: 90vw;
    gap: 30px;
    flex-direction: column;
  }

  .subPage_okulumuz-aboutUs_quote {
    width: 95%;
  }
}

@media screen and (max-width: 310px) {
  .subPages_okulumuz-egitimAnlayisimiz_firstColumn {
    width: 100%;
  }
}

@media screen and (orientation: portrait) {
  .subPages_okulumuz-ebrosur_whitespace:after {
    content: "E-Kitabı görüntülemek için cihazınızı yan çevirmeniz önerilir.";
    position: absolute;
    font-size: 12px;
    transform: translate(-50%, 100%);
    left: 50%;
    bottom: -10px;
    width: stretch;
    color: var(--red);
    text-align: center;
  }
}
