main {
  width: 100%;
  height: fit-content;
  display: block;
}

.hero {
  width: 100%;
  height: 80vh;

  position: relative;

  display: flex;

  justify-content: center;
  align-items: center;

  overflow: hidden;
}

.slider-hero {
  display: flex;
  flex-direction: column;
  gap: 30px;

  justify-content: center;
  align-items: center;
}

.slider-text-wrap {
  display: flex;
  flex-direction: column;
  gap: 10px;

  align-items: center;
  justify-content: center;
  text-align: center;

  color: var(--white);

  & h1 {
    font-size: 36px;
  }

  & p {
    font-size: 20px;
    font-weight: 300;
    max-width: 450px;
  }
}

.slider-button-wrap {
  display: flex;
  flex-direction: row;
  gap: 10px;

  height: 56px;

  justify-content: center;
  align-items: center;
}

.slider-button {
  border: none;
  border-radius: 10px;

  background-color: var(--white);

  display: flex;
  gap: 10px;
  align-items: center;
  justify-content: center;

  padding: 0 20px;
  height: 100%;
  width: 210px;

  font-weight: 500;
  font-size: 16px;

  cursor: pointer;

  transition-duration: 0.3s;

  &.fill {
    background-color: var(--brand);
    color: var(--white);
  }

  &.stroke {
    border: 2px solid var(--brand);
    color: var(--brand);
  }

  &:hover {
    filter: saturate(4);
  }
}

.slider-button.stroke svg > * {
  fill: var(--brand) !important;
}

.welly-image {
  width: 265px;
  height: 210px;
}

.slider {
  position: absolute;

  width: 100%;
  height: 100%;

  z-index: -1;

  background-color: black;
}

.slider-item {
  position: relative;
  width: 100%;
  height: 100%;

  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;

  position: absolute;

  &[data-selected="false"] {
    animation: fadeOut 0.4s forwards;
  }

  &[data-selected="true"] {
    animation: fadeIn 0.4s forwards;
  }

  &[id="1"] {
    background-image: url(https://api.kampuskres.com.tr/images/cdnimage/antrium-full.webp),
      url(../../assets/compressed/placeholder-antrium-full.webp);
  }
  &[id="2"] {
    background-image: url(https://api.kampuskres.com.tr/images/cdnimage/bahce-hayvanlar.webp);
    background-position: 30% 40%;
  }
  &[id="3"] {
    background-image: url(https://api.kampuskres.com.tr/images/cdnimage/civciv-antrium.webp);
  }
  &[id="4"] {
    background-image: url(https://api.kampuskres.com.tr/images/cdnimage/slider_4.webp);
    background-position: 0% 0%;
  }
}

.slider-item::after {
  content: "";

  position: absolute;
  width: 100%;
  height: 100%;

  background-color: #000;
  opacity: 0.55;
  z-index: -1;
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.slider-pagination-button {
  position: absolute;

  height: 100%;
  width: 83px;

  display: grid;
  place-items: center;

  cursor: pointer;
  border: none;

  &.left {
    left: 0;
    background: linear-gradient(
      90deg,
      rgba(0, 0, 0, 0.2) 65%,
      rgba(0, 0, 0, 0) 100%
    );

    &:hover {
      background: linear-gradient(
        90deg,
        rgba(0, 0, 0, 0.5) 65%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }
  &.right {
    right: 0;
    background: linear-gradient(
      -90deg,
      rgba(0, 0, 0, 0.2) 65%,
      rgba(0, 0, 0, 0) 100%
    );

    &:hover {
      background: linear-gradient(
        -90deg,
        rgba(0, 0, 0, 0.5) 65%,
        rgba(0, 0, 0, 0) 100%
      );
    }
  }

  & svg {
    --size: 20px;
    width: var(--size);
    height: var(--size);

    & * {
      stroke: var(--white);
      stroke-width: 2px;
    }
  }
}

.slider-pagination {
  height: 13px;
  width: 90px;
  border-radius: 20px;

  padding-block: 5px;

  display: flex;
  flex-direction: row;
  gap: 5px;

  justify-content: center;
  align-items: center;

  position: absolute;
  bottom: 20px;

  background-color: rgba(255, 255, 255, 0.2);

  & .slider-pagination-dot {
    height: 100%;
    aspect-ratio: 1/1;
    border-radius: 10px;

    background-color: var(--white);

    opacity: 0.5;

    transition-duration: 0.3s;

    &[data-selected="true"] {
      opacity: 1;
      width: 24px;
    }
  }
}

.instagram-feed {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  gap: 50px;

  /* & > div {
    width: 230px !important;
    height: 462px !important;
    position: relative !important;
  }

  & iframe {
    height: 460px;
    margin: 0 !important ;

    position: absolute;
    left: -49px;
    top: -55px;
  } */
}

.wrapbox {
  display: flex;
  flex-direction: row;
  gap: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;
  box-sizing: border-box;
  padding-inline: 84px;

  & > * {
    max-width: 560px;
    height: 393px;
  }
}

.instagram-gradient {
  background: linear-gradient(87deg, #f50000 0%, #b900b2 100%);
}

.homeContentBox.blurple {
  background: #2b2365;
}

.testing .Feedback {
  display: none !important;
}

.camelcase {
  text-transform: capitalize;
}

.youtubeBox {
  border: none;
  border-radius: 10px;
}

.contactGroups {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;

  padding-inline: 84px;

  max-width: 1308px;
  width: 100%;

  margin: auto;

  box-sizing: border-box;
}

.contactBigRowGroup {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
  align-items: center;
  width: 100%;

  & > * {
    max-width: 560px;
    height: 393px;

    box-sizing: border-box;
  }
}

.contactRowWrap {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  flex-direction: row;
  width: 100%;

  justify-content: center;
}

.contactItem {
  max-width: 373px;
  width: 100%;

  background-color: #372c88;
  padding: 5px 10px;
  border-radius: 5px;

  box-sizing: border-box;

  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: flex-start;
  align-items: center;

  font-weight: 300;
  font-size: 16px;

  text-decoration: none;

  color: var(--white);

  & > svg {
    width: 24px;
    height: 24px;
    flex-shrink: 0;

    opacity: 0.6;
  }
}

.contactItem:nth-child(1) {
  & > svg {
    width: 20px;
  }
}

.gridLayout {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  padding-inline: 84px;
  box-sizing: border-box;
  justify-content: flex-start;
  align-items: center;

  margin: auto;
}

.gridLayout .gridRow {
  display: flex;
  flex-direction: row;
  gap: 20px;
  width: 100%;
  justify-content: center;
  align-items: center;

  &:nth-child(even) {
    flex-direction: row-reverse;
  }
}

.gridBox {
  height: 360px;
  max-width: 650px;
  width: 100%;

  overflow: hidden;
  border-radius: 10px;

  display: flex;

  position: relative;

  box-sizing: border-box;

  & > img {
    object-fit: cover;
    width: 100%;
    height: 100%;
    flex-shrink: 0;
  }

  /* &::before {
    content: "";
    position: absolute;
    inset: 10%;
    backdrop-filter: blur(20px);
    -webkit-mask: linear-gradient(0deg, transparent, black 50%);
    width: 100%;
    height: 100%;
  } */

  &.sm {
    max-width: 460px;
  }
}

#revir > img {
  object-position: 90% 50%;
}
#beslenme > img {
  object-position: 100% 50%;
}
#yazokulu > img {
  object-position: 0% 60%;
}
#ciftdil > img {
  object-position: 0% 60%;
}

/* .gridBox:nth-child(4n + 1) {
  border: 3px solid var(--darkerGreen);
}
.gridBox:nth-child(4n + 2) {
  border: 3px solid var(--brand);
} */

.darkerGreen {
  color: var(--darkerGreen);
}

.gridBox::after {
  content: "";

  width: 100%;
  height: 100%;
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 50%,
    rgba(0, 0, 0, 1) 70%
  );
  opacity: 0.7;
  z-index: 1;

  position: absolute;
}
.gridBox.sm::after {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 70%,
    rgba(0, 0, 0, 1) 90%
  );
}

.gridBox .textBox {
  position: absolute;

  left: 20px;
  bottom: 20px;

  color: var(--white);

  display: flex;
  flex-direction: column;
  gap: 5px;

  max-width: 500px;

  z-index: 2;

  & p {
    font-size: 16px;
    font-weight: 300;
  }

  & h3 {
    font-weight: 500;
  }
}

.contactSocialWrap {
  display: flex;
  gap: 10px;
  justify-content: center;
  box-sizing: border-box;
}

.homeContentBox.brandBlue {
  background-color: #2482c4;
}

.contactSocial {
  /* padding: 10px 20px;
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
  border-radius: 5px; */

  display: grid;
  place-items: center;
  padding: 7px;
  border-radius: 5px;

  /* width: 200px; */

  color: var(--white);

  text-decoration: none;

  box-sizing: border-box;

  &.instagram {
    background: linear-gradient(87deg, #f50000 0%, #b900b2 100%);
  }
  &.youtube {
    background: #ff0033;
  }
  &.linkedin {
    background: #2867b2;
  }
  &.facebook {
    background: #0866ff;
  }
  &.wp > svg {
    --size: 30px !important;
  }
}

.floatingActions {
  position: fixed;
  right: 0;
  bottom: 0;
  transform: translate(0, 0);

  margin: 20px;

  z-index: 100;

  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;

  &::after {
    content: "";
    width: 170px;
    height: 130px;
    background-image: url(https://api.kampuskres.com.tr/images/cdnimage/welly-up-portrait.webp);
    background-size: cover;
    z-index: 1;
    position: absolute;
    bottom: 50px;
    right: -5px;
  }
}

.floatButton {
  --size: 50px;
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-items: center;

  height: var(--size);

  color: white;

  border-radius: 5px;

  filter: drop-shadow(0px 7px 12px rgba(1, 0, 36, 0.2));

  &.sm {
    width: var(--size);
    background: var(--blue);
  }
  &.lg {
    padding-inline: 20px;
    background: var(--white);
    color: var(--blue);
    text-decoration: none;
    box-sizing: border-box;
    border: 2px solid var(--blue);
    & > svg > * {
      fill: var(--blue);
    }
  }
}

.floatingActions.hide {
  animation: hide 0.3s forwards;
}
.floatingActions.show {
  animation: show 0.3s forwards;
}

@keyframes hide {
  0% {
    bottom: 0;
  }
  100% {
    bottom: -300px;
  }
}
@keyframes show {
  0% {
    bottom: -300px;
  }
  100% {
    bottom: 0;
  }
}

.wp {
  background-color: #25d366 !important;
  color: white;
}

.wp > svg {
  --size: 27px;
  width: var(--size);
  height: var(--size);
}

@media screen and (max-width: 1322px) {
  .contactItem {
    max-width: 560px;
  }
  .youtubeBox {
    max-width: 100%;
  }

  .googleMap {
    max-width: 560px;
  }

  /* .contactSocialWrap {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    gap: 10px;
    width: 100%;
    max-width: 560px;
  } */

  /* .contactSocial {
    justify-content: flex-start;

    width: 100%;

    padding: 10px 10px;

    & span {
      margin: auto;
    }
    & img {
      position: absolute;
    }
  } */
}

@media screen and (max-height: 650px) and (min-width: 1000px) {
  .welly-image {
    height: 160px;
    width: 200px;
  }
  .slider-text-wrap > h1 {
    font-size: 27px;
  }
  .slider-text-wrap > p {
    font-size: 18px;
  }
  .slider-button {
    height: 48px;
  }
}

@media screen and (max-width: 1000px) {
  .welly-image {
    animation: 0.3s fadeOut forwards;
    animation-delay: 3.2s;
  }
  .wrapbox,
  .contactGroups,
  .gridLayout {
    padding-inline: 20px;
  }

  .floatingActions {
    margin: 10px;
  }

  .contactBigRowGroup[data-formfirstmobile="true"] {
    flex-direction: column-reverse;
  }

  .wrapbox {
    flex-direction: column;
  }

  .gridLayout .gridRow {
    flex-direction: column !important;

    & .gridBox {
      min-width: 100% !important;
      height: 400px;

      &.sm {
        height: 300px;
      }
    }
  }

  .slider-pagination-button {
    width: 50px;
  }

  .slider-text-wrap {
    gap: 5px;
    & h1 {
      font-size: 24px;
    }
    & p {
      font-size: 16px;
      max-width: 350px;
    }
  }

  .slider-button-wrap {
    flex-direction: column;
    height: auto;
    width: calc(100% - 100px);
  }
  .slider-button {
    font-size: 16px !important;
    padding: 16px 20px !important;
    width: 100% !important;
  }

  .hero {
    align-items: flex-end;
  }

  .slider-hero {
    margin-bottom: 60px;
    width: 100%;
  }

  .slider-pagination {
    height: 10px;
    width: 80px;
  }
}

.contactModify .contactGroups {
  flex-direction: column-reverse;
}

.googleReviews {
  width: 100%;
  height: 260px;
  border: none;
  padding-inline: 20px;
  box-sizing: border-box;
}

.gridLayout[data-showall="false"] {
  max-height: 1000px;
  overflow: hidden;
  position: relative;
  margin-bottom: 10px;
}
.gridLayout[data-showall="false"]::after {
  content: "";
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 160px;
  background: linear-gradient(0deg, #f4f4f0 20%, rgba(255, 255, 255, 0) 100%);
  z-index: 1;
}
.showMoreButton {
  border: none;
  width: 100%;
  height: 60px;
  position: absolute;
  background-color: transparent;
  bottom: 20px;
  font-weight: 500;
  z-index: 2;
  cursor: pointer;
  font-size: 14px;
  color: black;
}

.showMoreButton[data-hide="true"] {
  display: none;
}

.homeContentBox {
  position: relative;
}

/* @media screen and (max-width: 480px) {
  .contactSocial {
    justify-content: flex-start;

    padding: 10px 10px;

    & span {
      margin: 0;
    }
    & img {
      position: relative;
    }
  }
} */
