.infoForm {
  --form-bg: var(--brand);
  --button-bg: #8a5110;

  display: flex;
  flex-direction: column;
  gap: 10px;

  padding: 22.5px 20px;
  border-radius: 10px;
  background-color: var(--brand);

  box-sizing: border-box;

  background-color: var(--form-bg);

  width: 560px;
  height: 393px;
  margin: 0;
}

.infoForm.purple {
  --form-bg: #372c88 !important;
  --button-bg: #5f4eda !important;
}

.infoForm.result {
  justify-content: center;
  align-items: center;
  color: var(--white);

  gap: 5px;

  text-align: center;

  & > p {
    max-width: 300px;
    font-weight: 300;
  }

  & > svg {
    width: 96px;
    height: 96px;
  }

  &.success > svg,
  &.error > svg {
    fill: var(--white);
  }

  &.error > svg {
    margin-bottom: 10px;
  }

  & > button {
    align-self: center;
    margin-top: 15px;
    padding-inline: 10px;
    width: auto;
  }
}

.infoForm input,
.infoForm select {
  height: 40px;

  border: none;
  border-radius: 7px;

  padding-inline: 15px;

  &[data-error="true"] {
    background-color: #d3020279;
    color: rgb(255, 240, 240);
    &::placeholder {
      color: rgb(255, 240, 240);
    }
  }
}

.infoForm label:before {
  display: none;
}

.infoForm input {
  box-sizing: border-box;
  width: 100%;
}

.infoForm select {
  width: 94px;
  cursor: pointer;
  background-color: var(--white);
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-inline: 15px;

  position: relative;

  &.input-lg {
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    /* no standardized syntax available, no ie-friendly solution available */
  }
  &::-ms-expand {
    display: none;
  }
}

.infoForm > button {
  cursor: pointer;
  border: none;
  width: 100px;
  height: 40px;
  border-radius: 7px;

  font-weight: 500;

  color: var(--white);
  background-color: var(--button-bg);

  align-self: flex-end;
}

.infoForm .rowInputGroup {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-self: center;

  width: 100%;
}

.infoForm .phoneInput {
  display: flex;
  flex-direction: row;
  gap: 10px;
  justify-content: center;
  align-self: center;

  width: 100%;
}

.infoForm h3 {
  color: var(--white);
}

.infoForm .input_checkbox {
  padding-left: 0px;
  color: rgba(255, 255, 255, 1);
  font-size: 15px;
  font-weight: 300;
}

.infoForm .input_checkbox input {
  flex: 0 0 auto;
  width: 20px;
  height: 20px;
  flex-shrink: 0;

  &:before {
    background: var(--brand);
  }

  &:after {
    border-color: var(--button-bg);
  }

  &:checked:after {
    background-color: var(--button-bg);
  }
}

.infoForm.purple .input_checkbox {
  color: rgba(255, 255, 255, 0.8);

  & input:before {
    background: #372c88;
  }
}

.infoForm[data-english="true"] select {
  width: 120px;
}

@media screen and (max-width: 1323px) {
  .infoForm {
    max-width: 100%;
    align-self: center;
  }
}
